<div id="mail-confirm" fxLayout="column">

    <div id="mail-confirm-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

        <div id="mail-confirm-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

            <div class="logo">
                <!-- <mat-icon class="mat-accent s-96">verified_user</mat-icon> -->
            </div>

            <div class="title">Company is Approved!</div>

            <div class="subtitle" style="padding-bottom: 50px;">
                <p>
                    {{status}}
                </p>
            </div>

            <mat-progress-bar mode="indeterminate" *ngIf="showProgress"></mat-progress-bar>
        </div>
    </div>
</div>