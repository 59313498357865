import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})

export class UserGuard implements CanActivate, CanActivateChild {
    constructor(
        private router: Router
    ) { }

    canActivate(): boolean {
        // if (!localStorage.getItem('userIdVal')) {
        //     this.router.navigate(['/sign-in']);
        //     return false;
        // }
        return true;
    }

    canActivateChild(): boolean {
        // if (!localStorage.getItem('userIdVal')) {
        //     this.router.navigate(['/sign-in']);
        //     return false;
        // }
        return true;
    }
}
