import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';

@Injectable()
export class AuthService
{
    private _authenticated: boolean = false;
    options = {
        headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };

    constructor(
        private httpClient: HttpClient,
        private userService: UserService,
        private router : Router
    ){ }
    
    set accessToken(token: string)
    {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string
    {
        return localStorage.getItem('accessToken') ?? '';
    }

    forgotPassword(email: string): Observable<any>
    {
        return this.httpClient.post('api/auth/forgot-password', email);
    }

    
    resetPassword(password: string): Observable<any>
    {
        return this.httpClient.post('api/auth/reset-password', password);
    }

    
    signInUsingToken(): Observable<any>
    {
        // Renew token
        return this.httpClient.post('api/auth/refresh-access-token', {
            accessToken: this.accessToken
        }).pipe(
            catchError(() =>

                // Return false
                of(false)
            ),
            switchMap((response: any) => {

                // Store the access token in the local storage
                this.accessToken = response.accessToken;

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                this.userService.user = response.user;

                // Return true
                return of(true);
            })
        );
    }

    signOut(): Observable<any>
    {
        // Remove the access token from the local storage
        this.accessToken = '';
        localStorage.removeItem('accessToken');
        localStorage.clear();
        this.router.navigate(['/sign-in']);
        // Set the authenticated flag to false
        this._authenticated = false;
        // Return the observable
        return of(true);
    }

    signUp(user: { name: string; email: string; password: string; company: string }): Observable<any>
    {
        return this.httpClient.post('api/auth/sign-up', user);
    }

    unlockSession(credentials: { email: string; password: string }): Observable<any>
    {
        return this.httpClient.post('api/auth/unlock-session', credentials);
    }
    
    check(): Observable<boolean>
    {
        // Check if the user is logged in
        if ( this._authenticated )
        {
            return of(true);
        }

        // Check the access token availability
        if ( !this.accessToken )
        {
            return of(false);
        }
        // Check the access token expire date
        if ( AuthUtils.isTokenExpired(this.accessToken) )
        {
            return of(false);
        }

        // If the access token exists and it didn't expire, sign in using it
        return this.signInUsingToken();
    }

    public signIn(username, password): Observable<any> {
        return this.httpClient.post<any>(`${environment.url}/authenticate`,{username,password}, this.options);
    }

    public authenticate(username, password) {
        return this.httpClient.post<any>(`${environment.url}/authenticate`,{username,password}).pipe(
         map(
           userData => {
            sessionStorage.setItem('username',username);
            let tokenStr= 'Bearer '+userData.token;
            sessionStorage.setItem('token', tokenStr);
            return userData;
           }
         )
    
        );
      }

    // public getUserAccessCount(id): Observable<any> {
    //     return this.httpClient.get(`${environment.url}/users/${id}/access-count`);
    // }

    public register(reqBody): Observable<any> {
        return this.httpClient.post(`${environment.url}/manage/account-request`,reqBody, this.options);
    }

    public forgot(reqBody): Observable<any> {
        return this.httpClient.post(`${environment.url}/users/account-reset`,reqBody, this.options);
    }

    public approveToken(token): Observable<any> {
        return this.httpClient.get(`${environment.url}/manage/account-approve?token=${token}`, this.options);
    }
}
