import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FileUploadModule } from 'ng2-file-upload';
import { TemplateGenerationComponent } from './template-generation/template-generation.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { DndListModule } from 'ngx-drag-and-drop-lists';
import { ImageCropperModule } from 'ngx-image-cropper';

@NgModule({
    declarations: [
        TemplateGenerationComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        FileUploadModule,
        MatFormFieldModule,
        MatInputModule,
        NgxSkeletonLoaderModule,
        DndListModule,
        ImageCropperModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        FileUploadModule,
        MatFormFieldModule,
        MatInputModule,
        TemplateGenerationComponent,
        NgxSkeletonLoaderModule,
        DndListModule,
        ImageCropperModule
    ]

})
export class SharedModule {
}
