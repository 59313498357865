import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';
import { UserGuard } from './core/auth/guards/user.guard';
import { ConfirmationTokenComponent } from './modules/auth/confirmation-token/confirmation-token.component';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    {path: '', pathMatch : 'full', redirectTo: 'sign-in'},

    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule)},
            {path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule)},
            // {path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule)},
            {path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule)},
            {path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule)},
            {path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule)},
            {path: 'recovery-mail', loadChildren: () => import('app/modules/auth/confirmation-mail/confirmation-required.module').then(m => m.AuthMailConfirmationRequiredModule)},
            {path: 'approve-company', component: ConfirmationTokenComponent},        ]
    },


    {
        path: '',
        component  : LayoutComponent,
        data: {
            layout: 'empty'
        },
        children   : [
            {path: 'home', loadChildren: () => import('app/modules/landing/home/home.module').then(m => m.LandingHomeModule)},
        ]
    },

    {
        path       : '',
        canActivate: [UserGuard],
        canActivateChild: [UserGuard],
        component  : LayoutComponent,
        resolve    : {
            initialData: InitialDataResolver,
        },
        children   : [

            // Dashboards
            {path: 'dashboard', children: [
                {path: 'companies', loadChildren: () => import('app/modules/admin/apps/companies/companies.module').then(m => m.CompaniesModule)},
                {path: 'groups', loadChildren: () => import('app/modules/admin/apps/groups/groups.module').then(m => m.GroupsModule)},
                {path: 'profile', loadChildren: () => import('app/modules/admin/apps/profile/profile.module').then(m => m.ProfileModule)},
                {path: 'facilities', loadChildren: () => import('app/modules/admin/apps/facilities/facilities.module').then(m => m.FacilitiesModule)},
                {path: 'coupons', loadChildren: () => import('app/modules/admin/apps/coupons/coupons.module').then(m => m.CouponsModule)},
                {path: 'promoImages', loadChildren: () => import('app/modules/admin/apps/promo-images/promo-images.module').then(m => m.PromoImagesModule)},
                {path: 'users', loadChildren: () => import('app/modules/admin/apps/users/users.module').then(m => m.UsersModule)},
            ]},
        ]
    }
];
